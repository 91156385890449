//import "bootstrap";

// Set copyright to current year
let year = document.getElementsByClassName("date-year")[0];
year.textContent = new Date().getFullYear();

// Check for submitted message form
let querystring = window.location.search;
if (querystring.includes("success")) {
  // Display success message
  let message = document.getElementsByClassName("message-success")[0];
  message.classList.remove("d-none");
}
